
  .filter-dropdown {
    flex: 1;
    position: relative;
    width: calc(33.33% - 6.67px);
    max-height:40px !important; 
  }
  
  .filter-dropdown input[type="text"] {
    padding: 5px;
    font-size: 1rem;
    width: 100%;
    position: relative;
    border-radius: 5px !important;
    padding-right: 30px;
  }
  
  .filter-dropdown label {
    display: none;
  }
  
  .dropdown-content {
    text-align: left !important;
    position: absolute;
    background-color: #f9f9f9;
    top: 37px;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 0px 0px 10px 10px;
    width:100%;
  }
  
  .dropdown-content label {
    display: block;
    padding: 5px;
    cursor: pointer;
  }
  
  .dropdown-content label:hover {
    background-color: #f1f1f1;
  }
  

  .search-input-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .selected-count {
    font-size:1rem !important;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 0.8em;
  }
.date-slider-container {
    width: 100%;
    height:50px !important;
    padding:5px 0px;
}
  
  .horizontal-slider {
    width: 100%;
    height: 25px;
  }
  
  .track {
    top: 10px;
    height: 10px;
    background: #ccc;
  }
  
  .track.track-1 {
    background: #333;
  }
  
  .thumb {
    top: 1px;
    width: 40px;
    height: 25px;
    line-height: 24px;
    border-radius:10px;
    text-align: center;
    background-color: #333;
    color: white;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
  }
  
  .date-range-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
.reader-button {
    background-color: transparent !important;
    padding:0px;
    width:auto;
    font-size:1rem;
}

.reader-button:hover {
    text-decoration: underline;
    color:black;
}

.reader-button:disabled {
    color:#999;
    font-weight: normal !important;

}

.reader-button:disabled:hover {
    color:#999 !important;
    text-decoration: none;
    font-weight: normal !important;
}


.reader-content h2{
text-align: center;
font-weight:normal;
font-size:2rem;
}

.page-text {
    font-size:1.25rem;
    direction: rtl;
}